import { useCallback, useContext, useMemo } from "react"

import type { CustomDate, State } from "~/contexts/datePicker"
import { DatePickerContext } from "~/contexts/datePicker"
import { toNumber } from "~/helpers/convert"
import type { EmptyCallback } from "~/types/components/callbacks"
import type { Day, Month } from "~/types/components/controls/datePicker"

type CustomDateCallback = (originDate: CustomDate) => CustomDate

type BooleanCallback = () => boolean

interface AvailableMonth {
	monthIndex: number
	monthName: string
	year: number
}

type SetStateCallback = (date: CustomDate, options: State["options"], extras: State["extras"]) => void
type UpdateStateCallback = (
	callback: (date: CustomDate) => CustomDate,
	options?: Partial<State["options"]>,
	extras?: Partial<State["extras"]>
) => void

const MONTHS_IN_YEAR = 12

/**
 * React hook to manage date picker components.
 * @returns {object} Current state & methods to manage the date picker.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.6.0
 */
export const useDatePicker = (): {
	futureYearCount: number

	date: State["date"]
	options: State["options"]
	extras: State["extras"]

	setState: SetStateCallback
	updateState: UpdateStateCallback

	availableYearEntries: Record<string, string>
	availableMonthEntries: Record<number, AvailableMonth> | null

	calculateIncrementDate: CustomDateCallback
	calculateDecrementDate: CustomDateCallback

	canIncrementMonth: BooleanCallback
	canDecrementMonth: BooleanCallback

	incrementMonth: EmptyCallback
	decrementMonth: EmptyCallback
} => {
	const { futureYearCount, futureMonthCount, state, setState } = useContext(DatePickerContext)
	if (futureYearCount === 0) console.warn("Date picker context not yet initialised!")

	const { availableYearEntries, minimumYear, maximumYear } = useMemo(() => {
		const currentYear = new Date().getFullYear()

		const futureYears = Array.from({ length: Math.max(futureYearCount, 1) }, (_, index) => {
			const year = currentYear + index
			return [year.toString(), year.toString()]
		})

		const minimumYear = toNumber(futureYears[0]?.[0]) ?? null
		if (minimumYear === null) throw new Error("Failed to calculate minimum year!")

		const maximumYear = toNumber(futureYears[futureYears.length - 1]?.[0]) ?? null
		if (maximumYear === null) throw new Error("Failed to calculate maximum year!")

		return {
			availableYearEntries: Object.fromEntries(futureYears) as Record<string, string>,
			minimumYear,
			maximumYear
		}
	}, [futureYearCount])

	const { availableMonthEntries, minimumMonthIndex, maximumMonthIndex } = useMemo(() => {
		const currentMonthIndex = new Date().getMonth()
		const currentYear = new Date().getFullYear()

		const monthNames = Array.from({ length: futureMonthCount }, (_, index) => {
			const monthIndex = (currentMonthIndex + index) % MONTHS_IN_YEAR

			const yearOffset = Math.floor((currentMonthIndex + index) / MONTHS_IN_YEAR)
			const year = currentYear + yearOffset

			const firstDay = new Date(year, monthIndex, 1, 12) // Midday to avoid rolling back during daylight savings
			const monthName = firstDay.toLocaleDateString("en-GB", { month: "long" })

			return [
				index,
				{
					monthIndex,
					monthName,
					year // So we can rollover to the following year(s)
				}
			] as [number, AvailableMonth]
		})

		const minimumMonthIndex = monthNames[0]?.[1]?.monthIndex
		if (minimumMonthIndex === undefined) throw new Error("Failed to calculate minimum month index!")

		const maximumMonthIndex = monthNames[monthNames.length - 1]?.[1]?.monthIndex
		if (maximumMonthIndex === undefined) throw new Error("Failed to calculate maximum month index!")

		return {
			availableMonthEntries: Object.fromEntries(monthNames) as Record<number, AvailableMonth>,
			minimumMonthIndex,
			maximumMonthIndex
		}
	}, [futureMonthCount])

	const calculateIncrementDate = useCallback<CustomDateCallback>(
		originDate => {
			// Go to the next month if the year isn't finished yet
			if (originDate.month < maximumMonthIndex)
				return { ...originDate, month: (originDate.month + 1) as Month, day: 1 }

			const nextYear = originDate.year + 1

			// Stay same if we've reached the last available year
			if (nextYear > maximumYear) return originDate

			// Go to the next year, starting on the 1st of January
			return { ...originDate, year: nextYear, month: 0, day: 1 }
		},
		[maximumYear, maximumMonthIndex]
	)

	const calculateDecrementDate = useCallback<CustomDateCallback>(
		originDate => {
			if (originDate.month === 0) {
				const previousYear = originDate.year - 1

				// Skip if we've reached the minimum remaining year
				if (previousYear < minimumYear) return originDate

				// Go to the previous year, but start on the 1st of December
				return { ...originDate, year: previousYear, month: 11, day: 1 }
			}

			const previousMonth = (originDate.month - 1) as Month

			// Skip if we've reached the minimum remaining month
			if (previousMonth < minimumMonthIndex) return originDate

			// Go to the previous month, starting on its last day
			const lastDay = new Date(originDate.year, previousMonth + 1, 0, 12) // Midday to avoid rolling back during daylight savings
			return { ...originDate, month: previousMonth, day: lastDay.getDate() as Day }
		},
		[minimumYear, minimumMonthIndex]
	)

	return {
		// Pass down from context initialisation
		futureYearCount,

		// Break up state for convenience
		date: state.date,
		options: state.options,
		extras: state.extras,

		// Convenience methods to update state
		setState: useCallback<SetStateCallback>(
			(date, options, extras) => {
				setState({
					date,
					options,
					extras
				})
			},
			[setState]
		),
		updateState: useCallback<UpdateStateCallback>(
			(callback, options, extras) => {
				setState(currentState => ({
					...currentState,

					date: callback(currentState.date),

					options: {
						...currentState.options,
						shouldRunChooseCallback:
							options?.shouldRunChooseCallback ?? currentState.options.shouldRunChooseCallback,
						findAvailableDaysFromEnd:
							options?.findAvailableDaysFromEnd ?? currentState.options.findAvailableDaysFromEnd
					},

					extras: {
						...currentState.extras,
						daysInPreviousMonth: extras?.daysInPreviousMonth ?? currentState.extras.daysInPreviousMonth
					}
				}))
			},
			[setState]
		),

		// For the drop-down choices
		availableYearEntries,
		availableMonthEntries: availableMonthEntries,

		// So the useMonthlyAvailable() hook can determine its own internal dates
		calculateIncrementDate,
		calculateDecrementDate,

		// Shortcuts to check if we can increment/decrement the current month/year
		canIncrementMonth: useCallback(
			() => calculateIncrementDate(state.date) !== state.date,
			[state.date, calculateIncrementDate]
		),
		canDecrementMonth: useCallback(
			() => calculateDecrementDate(state.date) !== state.date,
			[state.date, calculateDecrementDate]
		),

		// Shortcuts to increment/decrement the month/year in state
		incrementMonth: useCallback(() => {
			setState(currentState => ({
				...currentState,
				date: calculateIncrementDate(currentState.date),
				options: {
					...currentState.options,
					shouldRunChooseCallback: false,
					findAvailableDaysFromEnd: false
				}
			}))
		}, [setState, calculateIncrementDate]),
		decrementMonth: useCallback(() => {
			setState(currentState => ({
				...currentState,
				date: calculateDecrementDate(currentState.date),
				options: {
					...currentState.options,
					shouldRunChooseCallback: false,
					findAvailableDaysFromEnd: true
				}
			}))
		}, [setState, calculateDecrementDate])
	}
}
