import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import type { Reducer, UIEventHandler } from "react"
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { useDebounceCallback } from "usehooks-ts"

import Button from "~/components/controls/button"
import DropDownInput from "~/components/controls/inputs/dropDown"
import type { InputProps } from "~/components/controls/inputs/input"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Paragraph from "~/components/standard/text/paragraph"
import { dropDownIconSize, dropDownIconStyles } from "~/constants/components/dropDown"
import { fromRange } from "~/helpers/array"
import { clamp } from "~/helpers/clamp"
import type { CustomDateCallback } from "~/hooks/useAppointmentFilters"
import { useDatePicker } from "~/hooks/useDatePicker"
import { useFormSelector } from "~/hooks/useForm"
import type { OnClickCallback } from "~/types/components/controls/button"
import { ButtonThemes } from "~/types/components/controls/button"
import type {
	Day,
	IsDateAvailableCallback,
	Month,
	OnMonthChangeCallback,
	OnYearChangeCallback,
	ShortDayNames
} from "~/types/components/controls/datePicker"
import { isMonth, isYear } from "~/types/components/controls/datePicker"
import type { OnItemChooseCallback } from "~/types/components/controls/dropDown"
import type { ComponentProps } from "~/types/components/props"

/**
 * The remaining days for a chosen month, passed to the day selector child component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
interface RemainingDay {
	dayOfMonth: Day
	nameOfDay: ShortDayNames
	isAvailable: boolean
}

/**
 * The props of the date picker component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.4.2
 */
export type DatePickerProps = Pick<
	InputProps,
	"id" | "label" | "tooltip" | "isDisabled" | "isRequired" | "isFocused" | "isLoading" | "sideBySide"
> & {
	onChoose?: CustomDateCallback
	onMonthChange?: OnMonthChangeCallback
	onYearChange?: OnYearChangeCallback

	knownMonth?: Month // Required to prevent extra event firing when the month changes
	isDateAvailable?: IsDateAvailableCallback
}

/**
 * A date selector input.
 * Only shows future dates.
 * @example <DatePicker id="datePicker" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const DatePicker = ({
	id,

	label,
	tooltip = "Please select a date.",

	sideBySide = false,

	isDisabled,
	isRequired = true,
	isFocused,
	isLoading,

	onChoose,
	onMonthChange,
	onYearChange,

	knownMonth,
	isDateAvailable,

	...props
}: ComponentProps<HTMLDivElement, DatePickerProps>): JSX.Element => {
	const {
		futureYearCount,

		date,
		options,
		extras,

		updateState,

		availableYearEntries,
		availableMonthEntries,

		canIncrementMonth,
		canDecrementMonth,

		incrementMonth,
		decrementMonth
	} = useDatePicker()

	// The container for the scrollable days
	const scrollerContainerReference = useRef<HTMLDivElement>(null)

	// The remaining days in the chosen year & month combination
	const [remainingDays, setRemainingDays] = useState<RemainingDay[] | null>(null)

	// Map the remaining months
	const remainingMonthEntries = useMemo<Record<string, string> | null>(
		() =>
			Object.fromEntries(
				Object.entries(availableMonthEntries ?? {}).map<[string, string]>(([index, { monthName, year }]) => [
					index,
					year === new Date().getFullYear() ? monthName : `${monthName} ${year.toString()}`
				])
			),
		[availableMonthEntries]
	)

	// Map the selected available month
	const chosenAvailableMonthIndex = useMemo(
		() =>
			Object.entries(availableMonthEntries ?? {}).findIndex(
				([, { monthIndex, year }]) => monthIndex === date.month && year === date.year
			),
		[availableMonthEntries, date]
	)

	// Loading state
	const { isLoading: isFormLoading } = useFormSelector()
	const isAnyLoading = useMemo(
		() => (isLoading ?? isFormLoading) || remainingDays === null,
		[isLoading, isFormLoading, remainingDays]
	)

	// Recalculates the days left from today for the given year & month...
	const calculateRemainingDays = useCallback(
		(year: number, month: number) => {
			// Calculate the last day of the chosen month
			const lastDayOfMonth = new Date(year, month + 1, 0).getDate()

			// Strip out the days before today if we're in the current month
			const dateNow = new Date()
			const remainingDaysInMonth = fromRange(1, lastDayOfMonth).slice(
				year === dateNow.getFullYear() && month === dateNow.getMonth() ? dateNow.getDate() - 1 : 0
			)

			// Transform & store the remaining days
			return remainingDaysInMonth.map(
				day =>
					({
						dayOfMonth: day,
						isAvailable: isDateAvailable?.(new Date(year, month, day)) ?? true, // Assume available if no callback is provided
						nameOfDay: new Date(year, month, day).toLocaleString("en-GB", {
							weekday: "short"
						})
					}) as RemainingDay
			)
		},
		[isDateAvailable]
	)

	// Runs when the year drop-down changes...
	const onYearChoose = useCallback<OnItemChooseCallback>(
		(_, text) => {
			const year = parseInt(text)
			if (!isYear(year)) return // Don't allow out-of-range or otherwise invalid years

			updateState(
				currentDate => ({
					...currentDate,
					year
				}),
				{
					shouldRunChooseCallback: false
				}
			)
		},
		[updateState]
	)

	// Runs when the month drop-down changes, or changing year resets this...
	const onMonthChoose = useCallback<OnItemChooseCallback>(
		value => {
			// Ensure we're not being bamboozled
			const index = parseInt(value, 10)
			if (isNaN(index)) return

			// Ensure the month was actually returned earlier
			const availableMonth = availableMonthEntries?.[index]
			if (!availableMonth) return

			// Don't allow out-of-range or otherwise invalid months
			const monthIndex = availableMonth.monthIndex // Need to store locally for TypeScript guard from 'number' to 'Month'
			if (!isMonth(monthIndex)) return

			updateState(
				currentDate => ({
					...currentDate,
					month: monthIndex,
					year: availableMonth.year
				}),
				{
					shouldRunChooseCallback: false,
					findAvailableDaysFromEnd: false
				}
			)
			scrollerContainerReference.current?.scrollTo({
				left: 0,
				behavior: "smooth"
			})
		},
		[updateState, availableMonthEntries]
	)

	// Runs when a day is chosen, or changing month resets this...
	const onDayChoose = useCallback(
		(day: RemainingDay): void => {
			updateState(
				currentDate => ({
					...currentDate,
					day: day.dayOfMonth
				}),
				{
					shouldRunChooseCallback: true
				}
			)
		},
		[updateState]
	)

	// Runs when the previous week button is clicked to handle scrolling and rolling over to the previous month or year...
	const onPreviousWeekClick = useCallback<OnClickCallback>(() => {
		if (!scrollerContainerReference.current) return // Don't bother if we haven't got the scroller container yet

		// If we're at the start of the month, move to the previous month
		if (scrollerContainerReference.current.scrollLeft === 0) {
			decrementMonth()
			scrollerContainerReference.current.scrollTo({
				left: scrollerContainerReference.current.scrollWidth,
				behavior: "smooth"
			})
			return
		}

		// Otherwise, scroll back roughly a week's worth of days
		scrollerContainerReference.current.scrollBy({
			left: -scrollerContainerReference.current.clientWidth,
			behavior: "smooth"
		})
	}, [decrementMonth, scrollerContainerReference])

	// Runs when the next week button is clicked to handle scrolling and rolling over to the next month or year...
	const onNextWeekClick = useCallback<OnClickCallback>(() => {
		if (!scrollerContainerReference.current) return // Don't bother if we haven't got the scroller container yet

		// If we've scrolled to the end of the month, move to the next month
		// NOTE: Extra distance (5px) required as some devices don't scroll to the exact pixel (Pixel 7 on Android 14!)
		const amountScrolled =
			scrollerContainerReference.current.scrollLeft + scrollerContainerReference.current.clientWidth
		const maximumScroll = scrollerContainerReference.current.scrollWidth
		if (amountScrolled >= maximumScroll - 5) {
			incrementMonth()
			scrollerContainerReference.current.scrollTo({
				left: 0,
				behavior: "smooth"
			})
			return
		}

		// Otherwise, scroll ahead roughly a week's worth of days
		scrollerContainerReference.current.scrollBy({
			left: scrollerContainerReference.current.clientWidth,
			behavior: "smooth"
		})
	}, [incrementMonth, scrollerContainerReference])

	// Lets us update the navigation button states when the days container is scrolled...
	const [counter, recheckNavigationButtons] = useReducer<Reducer<number, void>>(value => value + 1, 0)

	const isNavigatePreviousPossible = useMemo(() => {
		if (!scrollerContainerReference.current) return false

		if (scrollerContainerReference.current.scrollLeft > 0) return true

		return canDecrementMonth()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canDecrementMonth, scrollerContainerReference, counter])

	// Should the forward button be grayed out?
	const isNavigateNextPossible = useMemo(() => {
		if (!scrollerContainerReference.current) return false

		const canIncrement = canIncrementMonth()
		if (canIncrement) return true

		const amountScrolled =
			scrollerContainerReference.current.scrollLeft + scrollerContainerReference.current.clientWidth
		const maximumScroll = scrollerContainerReference.current.scrollWidth

		return amountScrolled < maximumScroll
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canIncrementMonth, scrollerContainerReference, counter])

	// Runs when the user scrolls the days container...
	// NOTE: This needs to be debounced as onScroll is fired quite often! - https://usehooks-ts.com/react-hook/use-debounce-callback
	const onScroll = useDebounceCallback<UIEventHandler<HTMLDivElement>>(
		() => {
			recheckNavigationButtons()
		},
		150, // Roughly how long it takes to scroll a week's worth of days
		{
			// Run on click and after the delay
			leading: true,
			trailing: true
		}
	)

	// Update the remaining days & first available day when the month changes...
	useEffect(() => {
		if (isLoading === true) return // Don't bother if we're still loading the appointments

		// Don't bother if we don't have a date yet
		const chosenYear = date.year
		const chosenMonth = date.month

		// Don't bother if the isDateAvailable() callback won't return any results for this month
		if (knownMonth === undefined || knownMonth !== chosenMonth) return

		// Transform & store the remaining days
		const remainingDays = calculateRemainingDays(chosenYear, chosenMonth)
		setRemainingDays(remainingDays)

		// Get the first remaining day of the chosen month that is available
		const firstAvailableDay = options.findAvailableDaysFromEnd
			? [...remainingDays].reverse().find(day => day.isAvailable)
			: remainingDays.find(day => day.isAvailable)

		// Time to give up, the useMonthlyAppointments() hook should handle this
		if (!firstAvailableDay) {
			console.warn("No available days this month!")
			return
		}

		const daysInPreviousMonth = extras.daysInPreviousMonth
		const daysInThisMonth = new Date(chosenYear, chosenMonth + 1, 0).getDate()
		if (daysInPreviousMonth !== null && daysInPreviousMonth > daysInThisMonth) {
			console.warn(
				`Forcing scroll to the start! (previous month has ${daysInPreviousMonth.toString()} day(s), this month has ${daysInThisMonth.toString()})`
			)
			scrollerContainerReference.current?.scrollTo({
				left: 0,
				behavior: "smooth"
			})
		}

		// Otherwise, switch to the first available day & scroll to the appropriate position
		updateState(
			currentDate => ({
				...currentDate,
				month: chosenMonth,
				day: firstAvailableDay.dayOfMonth // NOTE: This will override the previously chosen day if the form is navigated away from & back to
			}),
			{
				shouldRunChooseCallback: true
			},
			{
				daysInPreviousMonth: daysInThisMonth
			}
		)

		const firstAvailableDayIndex = remainingDays.indexOf(firstAvailableDay)
		scrollerContainerReference.current?.scrollTo({
			left: clamp(
				firstAvailableDayIndex <= 3
					? 0
					: firstAvailableDayIndex >= remainingDays.length - 3
						? scrollerContainerReference.current.scrollWidth
						: firstAvailableDayIndex * (scrollerContainerReference.current.scrollWidth / daysInThisMonth),
				0,
				scrollerContainerReference.current.scrollWidth
			),
			behavior: "smooth"
		})
	}, [
		updateState,
		calculateRemainingDays,
		incrementMonth,
		knownMonth,
		isLoading,
		date.year,
		date.month,
		options.shouldRunChooseCallback,
		options.findAvailableDaysFromEnd,
		extras.daysInPreviousMonth
	])

	// Final stage of date being chosen...
	useEffect(() => {
		if (!options.shouldRunChooseCallback) return // We're locked!

		// Fire the day chosen event listener...
		console.info(
			`The chosen date is ${date.day.toString().padStart(2, "0")}/${(date.month + 1).toString().padStart(2, "0")}/${date.year.toString()}!`
		)
		onChoose?.({
			year: date.year,
			month: date.month,
			day: date.day
		})
	}, [onChoose, date.year, date.month, date.day, options.shouldRunChooseCallback]) // We must depend on each property, else we re-run the effect when the underlying reference changes!

	// Fire the month change event when just the month changes...
	useEffect(() => {
		onMonthChange?.(date.month)
	}, [onMonthChange, date.month])

	// Fire the year change event whenever just the year changes...
	useEffect(() => {
		onYearChange?.(date.year)
	}, [onYearChange, date.year])

	return (
		<div className={`flex justify-between ${sideBySide ? "flex-row gap-x-4" : "flex-col gap-y-3"}`}>
			<div className={`flex ${sideBySide ? "flex-grow flex-row gap-x-4" : "flex-col gap-y-2"}`}>
				{label !== undefined && (
					<label htmlFor={id} className="block text-left text-sm font-bold text-text">
						{label}
					</label>
				)}
				<div
					{...props}
					className={`flex flex-row justify-between gap-x-3 ${sideBySide ? "w-full flex-grow" : ""} ${props.className ?? ""} `}>
					{/* Month drop-down */}
					<DropDownInput
						id="month"
						label="Month"
						tooltip={tooltip}
						className="pe-8"
						choices={remainingMonthEntries ?? { "0": "Loading..." }}
						initialValue={chosenAvailableMonthIndex.toString()}
						isDisabled={isDisabled}
						isRequired={isRequired}
						isFocused={isFocused}
						isLoading={isAnyLoading}
						onChoose={onMonthChoose}
						startIcon={
							<CalendarDaysIcon
								className={`${dropDownIconStyles} fill-primary`}
								width={dropDownIconSize}
								height={dropDownIconSize}
							/>
						}
					/>

					{futureYearCount > 1 && (
						<DropDownInput
							id="year"
							label="Year"
							tooltip={tooltip}
							className="pe-8"
							choices={availableYearEntries}
							initialValue={date.year.toString()}
							isDisabled={isDisabled}
							isRequired={isRequired}
							isFocused={isFocused}
							isLoading={isAnyLoading}
							onChoose={onYearChoose}
							startIcon={
								<CalendarDaysIcon
									className={`${dropDownIconStyles} fill-primary`}
									width={dropDownIconSize}
									height={dropDownIconSize}
								/>
							}
						/>
					)}
				</div>
			</div>
			<div className={`flex flex-row gap-x-2 ${sideBySide ? "w-[50%]" : "w-full"}`}>
				{/* Left arrow */}
				<Button
					invisible={true}
					isDisabled={!isNavigatePreviousPossible}
					isLoading={isAnyLoading}
					onClick={onPreviousWeekClick}>
					<ChevronLeftIcon
						className={`mt-6 flex ${isAnyLoading || !isNavigatePreviousPossible ? "fill-gray-400 hover:cursor-not-allowed" : "fill-primary hover:cursor-pointer"}`}
						width={48}
					/>
				</Button>

				{/* Day scroller */}
				<div
					ref={scrollerContainerReference}
					id="dayScroller" // Required for ::-webkit-scrollbar styling in index.css
					className={`flex flex-grow flex-row gap-x-2 overflow-x-scroll ${remainingDays && remainingDays.length <= 5 ? "justify-center" : "justify-between"}`}
					onScroll={onScroll}>
					{remainingDays !== null ? (
						remainingDays.map(day => (
							<DayButton
								key={day.dayOfMonth}
								day={day}
								isSelected={day.dayOfMonth === date.day}
								isLoading={isAnyLoading}
								isDisabled={isDisabled}
								onChoose={onDayChoose}
							/>
						))
					) : (
						<LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />
					)}
				</div>

				{/* Right arrow */}
				<Button
					invisible={true}
					isDisabled={!isNavigateNextPossible}
					isLoading={isAnyLoading}
					onClick={onNextWeekClick}>
					<ChevronRightIcon
						className={`mt-6 flex ${isAnyLoading || !isNavigateNextPossible ? "fill-gray-400 hover:cursor-not-allowed" : "fill-primary hover:cursor-pointer"}`}
						width={48}
					/>
				</Button>
			</div>
		</div>
	)
}

/**
 * Small circular button with the day name above it.
 * @example <DayOfWeekButton ... />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const DayButton = ({
	day,
	isLoading,
	isDisabled,
	isSelected,
	onChoose,

	...props
}: ComponentProps<
	HTMLDivElement,
	Pick<InputProps, "isLoading" | "isDisabled"> & {
		day: RemainingDay
		isSelected?: boolean
		onChoose?: (day: RemainingDay) => void
	}
>): JSX.Element => {
	// Calculate final states of the button
	const beLoading = useMemo(() => isLoading ?? false, [isLoading])
	const beDisabled = useMemo(
		() => beLoading || !day.isAvailable || (isDisabled ?? false),
		[beLoading, isDisabled, day.isAvailable]
	)
	const beSelected = useMemo(() => !beDisabled && (isSelected ?? false), [beDisabled, isSelected])

	// Inject the day into the click callback...
	const onClick = useCallback<OnClickCallback>(() => {
		onChoose?.(day)
	}, [onChoose, day])

	return (
		<div {...props} className={`flex flex-col justify-center gap-y-2 ${props.className ?? ""}`.trimEnd()}>
			<Paragraph className="text-center text-sm font-bold text-text">{day.nameOfDay}</Paragraph>
			<Button
				invisible={false}
				wide={true}
				circle={true}
				label={day.dayOfMonth.toString()}
				isDisabled={beDisabled}
				theme={beSelected ? ButtonThemes.WhiteOnPrimary : ButtonThemes.TextOnWhite}
				className={`h-[2.5rem] w-[2.5rem] ${isSelected === false ? "!border-controlBorder" : ""}`.trimEnd()}
				onClick={onClick}
			/>
		</div>
	)
}

export default DatePicker
